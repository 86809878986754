import React, { useEffect, useState, useCallback } from 'react';
import DatePicker from 'react-datepicker';
import FieldTitle from './FieldTitle';
import TextInput from './TextInput';
import './DatePicker.css';

function SubscriberPaymentDetails({ value = {}, update }) {
    const { start_date = null, end_date = null, total_amount = 0, amount_received = 0 } = value;
    const [startDate, setStartDate] = useState(start_date ? new Date(start_date) : null);
    const [endDate, setEndDate] = useState(end_date ? new Date(end_date) : null);
    const [subscriptionAmount, setSubscriptionAmount] = useState(total_amount);
    const [subscriptionAmountReceived, setSubscriptionAmountReceived] = useState(amount_received);

    useEffect(() => {
        if (
            startDate !== start_date ||
            endDate !== end_date ||
            subscriptionAmount !== total_amount ||
            subscriptionAmountReceived !== amount_received
        ) {
            update({
                start_date: startDate,
                end_date: endDate,
                total_amount: subscriptionAmount,
                amount_received: subscriptionAmountReceived,
            });
        }
    }, [startDate, endDate, subscriptionAmount, subscriptionAmountReceived]);

    return (
        <div>
            <FieldTitle marginTop="2px" title="Subscription Details" />
            <div className="flex flex-col sm:flex-row w-full justify-between mt-2">
                {/* Subscription Start Date */}
                <div className="subscriber-payment-datepicker w-1/2">
                    <FieldTitle marginTop="2px" title="Subscription Start Date" />
                    <DatePicker
                        className="subscriber-payment-datepicker-input w-full bg-white shadow rounded p-sm text-xs placeholder-primary"
                        placeholderText="Start Date"
                        selected={startDate}
                        onChange={setStartDate}
                    />
                </div>
                {/* Subscription End Date */}
                <div className="subscriber-payment-datepicker w-1/2">
                    <FieldTitle marginTop="2px" title="Subscription End Date" />
                    <DatePicker
                        className="subscriber-payment-datepicker-input w-full bg-white shadow rounded p-sm text-xs placeholder-primary"
                        placeholderText="End Date"
                        selected={endDate}
                        onChange={setEndDate}
                    />
                </div>
            </div>

            <div className="flex justify-between mt-2">
                {/* Subscription Amount */}
                <div className="relative w-1/2">
                    <TextInput
                        placeholder="Subscription Amount"
                        type="number"
                        value={subscriptionAmount}
                        update={(val) => setSubscriptionAmount(parseFloat(val) || 0)}
                    />
                </div>
                {/* Amount Received */}
                <div className="relative w-1/2">
                    <TextInput
                        placeholder="Amount Received"
                        type="number"
                        value={subscriptionAmountReceived}
                        update={(val) => setSubscriptionAmountReceived(parseFloat(val) || 0)}
                    />
                </div>
            </div>

            {/* Due Amount */}
            <div className="flex justify-between mt-2">
                <div className="relative w-1/2">
                    <TextInput
                        placeholder="Due Amount"
                        type="number"
                        disabled
                        value={Math.max(0, subscriptionAmount - subscriptionAmountReceived)}
                    />
                </div>
            </div>
        </div>
    );
}

export default SubscriberPaymentDetails;
