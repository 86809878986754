import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase from 'firebase/app';
import 'firebase/auth';
import { navigate } from 'gatsby';
import { useEffect, useState } from 'react';
import EditImporterForm from '../../../forms/importer/EditImporterForm';
import React from 'react';

const UPDATE_IMPORTER_ACCOUNT = gql`
  mutation updateImporter($id: uuid, $changes: importers_set_input) {
    update_importers(where: { id: { _eq: $id } }, _set: $changes) {
      affected_rows
      returning {
        recruiter_id
        username
        password
        authorization
        account_type
        id
        sync_frequency
      }
    }
  }
`;

const ADD_IMPORTER_ACCOUNT = gql`
  mutation addImporters($importer: [importers_insert_input!]!) {
    insert_importers(objects: $importer) {
      affected_rows
      returning {
        recruiter_id
        authorization
        username
        password
        account_type
        id
        sync_frequency
      }
    }
  }
`;

export default function LoxoModalEdit({ state, onClose }) {
  const [modal, setModal] = state;
  const [fadeDelay, setFadeDelay] = useState(false);

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (modal.open) {
      setFadeDelay(true);
    } else {
      setTimeout(() => {
        setFadeDelay(false);
      }, 300);
    }
  }, [modal]);

  const initialFields = {
    // username: modal.data && modal.data.username ? modal.data.username : '',
    authorization: modal.data && modal.data.authorization ? modal.data.authorization?.split("Bearer ").pop().trim() : '',
    sync_frequency: modal.data && modal.data.sync_frequency ? modal.data.sync_frequency : 'DAILY',
  };
  const [addImporter, { data: importer_create_response, loading: createloading }] = useMutation(ADD_IMPORTER_ACCOUNT);
  const [updateImporter, { data: importer_update_response, loading: updateloading }] = useMutation(UPDATE_IMPORTER_ACCOUNT);

  const editImporter = async (data) => {
    const authToken = data?.authorization?.includes("Bearer ") ? data?.authorization?.split("Bearer ")[1] : data?.authorization || '';
    if (modal.data) {
      const encodedString = Buffer.from(data.username + ':' + data.password).toString('base64');

      let update_data = {
        // username: data?.username,
        // password: data.password,
        authorization: 'Bearer ' + authToken,
        sync_frequency: data.sync_frequency,
      };
      await updateImporter({
        variables: {
          id: modal.data.id,
          changes: update_data,
        },
      });
    } else {
      const encodedString = Buffer.from(data.username + ':' + data.password).toString('base64');

      let insert_data = {
        recruiter_id: firebase.auth().currentUser && firebase.auth().currentUser.uid,
        account_type: 'loxo',
        // username: data.username,
        // username: data.username,
        // password: data.password,
        authorization: 'Bearer ' + authToken,
        sync_frequency: data.sync_frequency,
        is_connected: true,
      };
      await addImporter({
        variables: { importer: [insert_data] },
      });
    }
  };

  useEffect(() => {
    if (importer_update_response || importer_create_response) {
      setModal({ open: false, data: null });
    }
  }, [importer_update_response, importer_create_response]);

  return (
    <div
      className="py-lg fixed inset-0 flex items-center overflow-auto justify-center"
      style={{
        display: fadeDelay ? 'flex' : 'none',
        zIndex: 1000000000,
        opacity: modal.open ? 1 : 0,
        transition: 'opacity 0.2s ease-in-out',
        backgroundColor: 'rgba(34, 46, 66, 0.9)',
      }}
    >
      <EditImporterForm
        initialValues={initialFields}
        saveImporter={editImporter}
        navigate={navigate}
        loading={modal.data ? updateloading : createloading}
        errors={errors}
        state={[modal, setModal]}
        onClose={onClose}
      />
    </div>
  );
}
