import { useMutation, useQuery, useSubscription } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { Link, navigate } from 'gatsby';
import parse from 'html-react-parser';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Arrow, RequestInterviewButton } from '../../components/common';
import UnblockButton from '../../components/common/UnblockButton';
import { ConfirmModalContext } from '../../context';
import UserRoleContext from '../../context/UserRole';
import EditIcon from '../../images/edit.svg';
import EmailIcon from '../../images/email.svg';
import LocationIcon from '../../images/location.svg';
import PhoneIcon from '../../images/phone.svg';
import UserAvatar from '../../images/user-avatar.png';
import { formatSalary } from '../../utils/formatter';
import AdditionalNotes from './additional-notes';
import './job-details.css';
import './JobStyles.css';
import MatchedCandidates from './matched-candidates';
import TrashButton from './TrashIcon';
import helper from '../../utils/job-candidate-helper';
import BackButton from '../../components/common/BackButton';

const GET_ALL_CANDIDATES = gql`
  query jobCandidates($recruiterID: String, $jobID: uuid) {
    candidate(where: { recruiter_id: { _eq: $recruiterID } }) {
      id
      name
    }
    job_candidate(where: { job_id: { _eq: $jobID } }) {
      candidate_id
    }
  }
`;

const GET_MATCHED_CANDIDATES = gql`
  subscription matchedCandidates($jobID: uuid) {
    job_candidate(where: { job_id: { _eq: $jobID }, _and: { status: { _eq: "matched" } } }) {
      candidate {
        id
        name
        profilePictureURL
        employment
        location
        phone
        email
        recruiter_id
        referralName
        linkedin
        facebook
        instagram
        twitter
        salaryMax
        salaryMin
        attributes
        bio
        experience
        interestedCity
        preferredSkills
        recruiter {
          id
          name
          email
        }
        locationPreferences
        invite_code
        is_login
        device_token
      }
      company_requests {
        company_id
        status
        isDirectRequest
        company {
          id
          adminID
        }
      }
      status
    }
  }
`;

const GET_INTERESTED_CANDIDATES = gql`
  subscription matchedCandidates($jobID: uuid) {
    job_candidate(where: { job_id: { _eq: $jobID }, status: { _eq: "interested" } }) {
      candidate {
        id
        name
        profilePictureURL
        employment
        location
        phone
        email
        recruiter_id
        referralName
        linkedin
        facebook
        instagram
        twitter
        salaryMax
        salaryMin
        attributes
        bio
        experience
        interestedCity
        preferredSkills
        recruiter {
          id
          name
          email
          notification_settings {
            notification_ids
          }
        }
        locationPreferences
        invite_code
        is_login
        device_token
      }
      company_requests {
        company_id
        status
        isDirectRequest
        company {
          id
          adminID
        }
      }
      job {
        company_id
        subscriber_id
      }
      status
    }
  }
`;

const GET_NOT_INTERESTED_CANDIDATES = gql`
  subscription matchedCandidates($jobID: uuid) {
    job_candidate(where: { job_id: { _eq: $jobID }, status: { _eq: "not interested" } }) {
      candidate {
        id
        name
        profilePictureURL
        employment
        location
        phone
        email
        recruiter_id
        referralName
        linkedin
        facebook
        instagram
        twitter
        salaryMax
        salaryMin
        attributes
        bio
        experience
        interestedCity
        preferredSkills
        recruiter {
          id
          name
          email
          notification_settings {
            notification_ids
          }
        }
        locationPreferences
        invite_code
        is_login
        device_token
      }
      company_requests {
        company_id
        status
        isDirectRequest
        company {
          id
          adminID
        }
      }
      job {
        company_id
        subscriber_id
      }
      status
    }
  }
`;

// const GET_NOT_INTERESTED_CANDIDATES = gql`
//   subscription matchedCandidates($jobID: uuid) {
//     job_candidate(where: { job_id: { _eq: $jobID }, status: { _eq: "not interested" } }) {
//       candidate {
//         id
//         name
//         profilePictureURL
//         employment
//         location
//         phone
//         email
//         recruiter_id
//         locationPreferences
//         invite_code
//         is_login
//       }
//       company_request {
//         company_id
//         status
//       }
//       status
//     }
//   }
// `;

const GET_JOB_INTERVIEWS = gql`
  subscription jobInterviews($jobID: uuid) {
    job_interview(where: { job_id: { _eq: $jobID } }) {
      candidate {
        id
        name
        recruiter_id
        profilePictureURL
        employment
        location
        phone
        email
      }
    }
  }
`;

const MATCH_CANDIDATES = gql`
  mutation matchCandidates($matchedCandidates: [job_candidate_insert_input!]!) {
    insert_job_candidate(objects: $matchedCandidates) {
      returning {
        job {
          title
        }
        candidate {
          id
          name
          profilePictureURL
          employment
          location
          phone
          email
          recruiter_id
          referralName
          linkedin
          facebook
          instagram
          twitter
          salaryMax
          salaryMin
          attributes
          bio
          experience
          interestedCity
          preferredSkills
          recruiter {
            id
            name
            email
          }
          locationPreferences
          invite_code
          is_login
          device_token
        }
        company_requests {
          company_id
          status
          isDirectRequest
          company {
            id
            adminID
          }
        }
      }
    }
  }
`;

const GET_JOB = gql`
  subscription getJob($jobID: uuid) {
    job(where: { id: { _eq: $jobID } }) {
      id
      industry
      location
      notes
      recruiter {
        id
        name
      }
      company {
        id
        name
        logoURL
      }
      optionalAttributes
      requiredAttributes
      requiredExperience
      salaryMax
      salaryMin
      status
      title
      hiddenFromCandidates
      allowsRemote
      filled
      description
      compensation_list
      subscriber_id
      adminApproved
      subscriber {
        id
        name
      }
      created_at
    }
  }
`;

const DELETE_JOB = gql`
  mutation deleteJob($id: uuid) {
    delete_job(where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

const GET_COMPANY_ID = gql`
  query getCompanyID($admin_id: String) {
    company(where: { adminID: { _eq: $admin_id } }) {
      id
      remainingCredit
      subscription_type
    }
  }
`;

const AddCandidateButton = ({ jobID, jobCandidates, candidates }) => {
  const [dropdown, setDropdown] = useState(false);
  const [matchedCandidates, setMatchedCandidates] = useState([]);
  const [matchCandidatesWithJob] = useMutation(MATCH_CANDIDATES);
  const hideDropdown = () => setDropdown(false);

  useEffect(() => {
    if (dropdown) {
      window.addEventListener('click', hideDropdown);
    } else {
      if (matchedCandidates.length > 0) {
        const newJobCandidates = matchedCandidates.map((candidate) => ({
          job_id: jobID,
          candidate_id: candidate,
          status: 'matched',
        }));
        matchCandidatesWithJob({
          variables: { matchedCandidates: newJobCandidates },
          refetchQueries: ['matchedCandidates', 'jobCandidates'],
        });
        setMatchedCandidates([]);
      }
      return window.removeEventListener('click', hideDropdown);
    }
  }, [dropdown]);

  const [inputValue, setInputValue] = useState('');

  return (
    <div className="relative">
      <button
        onClick={(e) => {
          e.stopPropagation();
          setDropdown(!dropdown);
        }}
        className="bg-green text-white rounded text-sm px-md font-medium"
        style={{ height: 40, borderRadius: 40 }}
      >
        Send to Candidate
      </button>
      {dropdown && (
        <div className="absolute bg-white rounded shadow overflow-hidden" style={{ top: '120%', left: 0, width: 220, zIndex: 10000, maxHeight: 420 }}>
          <input
            type="text"
            value={inputValue}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => setInputValue(e.target.value)}
            className="bg-white w-full shadow rounded p-sm text-xs placeholder-primary font-normal"
            placeholder={`Search for candidates`}
          />
          {candidates
            .filter((candidate) => candidate.name.toLowerCase().includes(inputValue.toLowerCase()))
            .map((candidate, index) =>
              jobCandidates.includes(candidate.id) ? null : (
                <div
                  key={index}
                  onClick={(e) => e.stopPropagation()}
                  className={`text-sm flex justify-between items-center py-sm px-md cursor-pointer ${matchedCandidates.includes(candidate.id) ? 'text-white bg-green' : 'bg-white hover:bg-lightgray'
                    }`}
                >
                  <a href={`candidates/${candidate.id}`} target="_blank" rel="noreferrer">
                    {candidate.name}
                  </a>
                  <input
                    checked={matchedCandidates.includes(candidate.id)}
                    type="checkbox"
                    onChange={(e) => {
                      e.stopPropagation();
                      if (matchedCandidates.includes(candidate.id)) {
                        setMatchedCandidates(matchedCandidates.filter((id) => id !== candidate.id));
                      } else {
                        setMatchedCandidates([...matchedCandidates, candidate.id]);
                      }
                    }}
                  />
                </div>
              ),
            )}
        </div>
      )}
    </div>
  );
};

const CandidateEntry = ({ candidate, disableChat, isSubscriber, jobID }) => {
  const [userRole] = useContext(UserRoleContext);
  const currentEmployment = (candidate?.employment || [])?.filter((a) => a.tenure.current)[0];
  return (
    <div style={{ padding: 30, paddingBottom: 0 }}>
      <div className="flex border-b" style={{ paddingBottom: 30 }}>
        <div
          style={{
            height: 48,
            width: 48,
            borderRadius: 24,
            backgroundImage: `url(${candidate.profilePictureURL ? candidate.profilePictureURL : UserAvatar})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        />
        <div className="flex flex-col" style={{ paddingLeft: 15, flexGrow: 1, minWidth: 225, maxWidth: 225 }}>
          <div
            onClick={() => {
              if (candidate.recruiter_id === firebase.auth().currentUser.uid || userRole === 'admin' || isSubscriber) {
                navigate(`/candidates/${candidate.id}`, {
                  state: { candidate, isSubscriber, jobID },
                });
              }
            }}
            className="font-medium text-darkblue cursor-pointer hover:underline"
            style={{ fontSize: 16, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', paddingRight: 10, paddingTop: disableChat ? 12 : 0 }}
          >
            {candidate.name}
          </div>
          {!disableChat && (
            <div
              onClick={() => {
                navigate('/chat', {
                  state: {
                    candidate: {
                      id: candidate.id,
                      name: candidate.name,
                      profilePicture: candidate.profilePictureURL,
                      messages: [],
                    },
                    from: 'Job',
                  },
                });
              }}
              className="text-green font-medium cursor-pointer hover:underline"
              style={{ fontSize: 14 }}
            >
              Chat
            </div>
          )}
        </div>
        <div className="flex-col font-medium" style={{ fontSize: 14, flexGrow: 1, minWidth: 208, maxWidth: 208 }}>
          <div className="text-darkblue">Current Position</div>
          <div className="text-darkgray">{candidate.employment.length > 0 ? currentEmployment?.title : 'Job searching'}</div>
          {candidate.employment.length > 0 && <div className="text-darkgray">{currentEmployment?.companyName}</div>}
        </div>
        <div className="flex flex-col" style={{ fontSize: 14, minWidth: 173, maxWidth: 173 }}>
          {candidate.location && (
            <div className="flex font-medium text-darkgray items-center">
              <div style={{ width: 25 }}>
                <img alt="" src={LocationIcon} style={{ width: 10.2, height: 14.5, marginBottom: 1, marginLeft: 2, marginRight: 10 }} />
              </div>
              {candidate.location.city}
            </div>
          )}
          {candidate.phone && (
            <div className="flex font-medium text-darkgray items-center">
              <div style={{ width: 25 }}>
                <img alt="" src={PhoneIcon} style={{ width: 9.3, height: 14.5, marginBottom: 1, marginLeft: 2, marginRight: 10 }} />
              </div>
              {candidate.phone}
            </div>
          )}
          <div className="flex font-medium text-darkgray items-center">
            <div style={{ width: 25 }}>
              <img alt="" src={EmailIcon} style={{ width: 14.3, height: 10.8, marginBottom: 1, marginRight: 10 }} />
            </div>
            <div style={{ maxWidth: 148.81, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{candidate.email}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { CandidateEntry };

export const JobCandidateEntry = ({ candidate, disableChat, isSubscriber, jobID, job, activeTab }) => {
  const [userRole] = useContext(UserRoleContext);

  const { data } = useQuery(GET_COMPANY_ID, {
    variables: {
      admin_id: firebase.auth().currentUser && firebase.auth().currentUser.uid,
    },
  });

  const currentEmployment = candidate?.employment?.sort((a, b) => {
    const x = a.tenure.current;
    const y = b.tenure.current;
    return (x === y)? 0 : x? -1 : 1;
  })?.[0];
  const candidateStatus = (helper.getCandidateOrRequestStatusIsApproved(candidate, job) || ['hired', 'approved'].includes(candidate?.status)) ? 'approved' : candidate?.status;
  return (
    <div style={{ padding: 30, paddingBottom: 0 }}>
      <div className="flex border-b" style={{ paddingBottom: 30 }}>
        <div
          style={{
            height: 48,
            width: 48,
            borderRadius: 24,
            backgroundImage: `url(${helper.getCandidateOrRequestStatusIsApproved(candidate, job) ? (candidate.profilePictureURL ? candidate.profilePictureURL : UserAvatar) : UserAvatar})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            filter: `${!helper.getCandidateOrRequestStatusIsApproved(candidate, job) ? 'blur(6px)' : ''}`,
          }}
        />
        <div className="flex flex-col" style={{ paddingLeft: 15, flexGrow: 1, minWidth: 225, maxWidth: 225 }}>
          <div
            onClick={() => {
              if (candidate.recruiter_id === firebase.auth().currentUser.uid || userRole === 'admin' || isSubscriber) {
                navigate(`/candidates/${candidate.id}`, {
                  state: { candidate, isSubscriber, jobID },
                });
              }
            }}
            className="font-medium text-darkblue cursor-pointer hover:underline"
            style={{
              fontSize: 16,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              paddingRight: 10,
              paddingTop: disableChat ? 12 : 0,
              color: `${!helper.getCandidateOrRequestStatusIsApproved(candidate, job) ? 'transparent' : ''}`,
              textShadow: `${!helper.getCandidateOrRequestStatusIsApproved(candidate, job) ? 'black 0px 0px 7px' : ''}`,
            }}
          >
            {helper.getCandidateOrRequestStatusIsApproved(candidate, job) ? candidate.name : 'Candidate Name'}
          </div>
          {activeTab === 'not-interested' ? (
            ''
          ) : (
            <div className="text-darkgray" style={{ fontSize: 14, flexGrow: 1, minWidth: 208, maxWidth: 208 }}>
              {currentEmployment?.title || 'Job searching'}
            </div>
          )}
        </div>
        {activeTab === 'not-interested' ? (
          <div className="flex-col font-medium" style={{ fontSize: 14, flexGrow: 1, minWidth: 208, maxWidth: 208 }}>
            <div className="text-darkblue">Current Position</div>
            <div
              className="text-darkgray"
              style={{
                color: `${!helper.getCandidateOrRequestStatusIsApproved(candidate, job) ? 'transparent' : ''}`,
                textShadow: `${!helper.getCandidateOrRequestStatusIsApproved(candidate, job) ? 'black 0px 0px 7px' : ''}`,
                filter: `${!helper.getCandidateOrRequestStatusIsApproved(candidate, job) ? 'blur(5px)' : ''}`,
              }}
            >
              {candidate.employment.length > 0 ? candidate.employment[0].title : 'Job searching'}
            </div>
            {candidate.employment.length > 0 && (
              <div
                className="text-darkgray"
                style={{
                  color: `${!helper.getCandidateOrRequestStatusIsApproved(candidate, job) ? 'transparent' : ''}`,
                  textShadow: `${!helper.getCandidateOrRequestStatusIsApproved(candidate, job) ? 'black 0px 0px 7px' : ''}`,
                  filter: `${!helper.getCandidateOrRequestStatusIsApproved(candidate, job) ? 'blur(5px)' : ''}`,
                }}
              >
                {candidate.employment[0].companyName}
              </div>
            )}
          </div>
        ) : (
          ''
        )}
        <div className="flex flex-col" style={{ fontSize: 14, minWidth: 173, maxWidth: 173 }}>
          {candidate.location && (
            <div className="flex font-medium text-darkgray items-center">
              <div style={{ width: 25 }}>
                <img alt="" src={LocationIcon} style={{ width: 10.2, height: 14.5, marginBottom: 1, marginLeft: 2, marginRight: 10 }} />
              </div>
              {candidate.location.city}
            </div>
          )}
          {candidate.phone && (
            <div
              className="flex font-medium text-darkgray items-center"
              style={{
                color: `${!helper.getCandidateOrRequestStatusIsApproved(candidate, job) ? 'transparent' : ''}`,
                textShadow: `${!helper.getCandidateOrRequestStatusIsApproved(candidate, job) ? 'black 0px 0px 7px' : ''}`,
                filter: `${!helper.getCandidateOrRequestStatusIsApproved(candidate, job) ? 'blur(5px)' : ''}`,
              }}
            >
              <div style={{ width: 25 }}>
                <img alt="" src={PhoneIcon} style={{ width: 9.3, height: 14.5, marginBottom: 1, marginLeft: 2, marginRight: 10 }} />
              </div>
              {helper.getCandidateOrRequestStatusIsApproved(candidate, job) ? candidate.phone : 'Candidate phone'}
            </div>
          )}
          <div className="flex font-medium text-darkgray items-center">
            <div style={{ width: 25 }}>
              <img alt="" src={EmailIcon} style={{ width: 14.3, height: 10.8, marginBottom: 1, marginRight: 10 }} />
            </div>
            <div
              style={{
                maxWidth: 148.81,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                color: `${!helper.getCandidateOrRequestStatusIsApproved(candidate, job) ? 'transparent' : ''}`,
                textShadow: `${!helper.getCandidateOrRequestStatusIsApproved(candidate, job) ? 'black 0px 0px 7px' : ''}`,
                filter: `${!helper.getCandidateOrRequestStatusIsApproved(candidate, job) ? 'blur(5px)' : ''}`,
              }}
            >
              {helper.getCandidateOrRequestStatusIsApproved(candidate, job) ? candidate.email : 'Candidate email'}
            </div>
          </div>
        </div>
        <div className="flex flex-1 justify-end relative mt-sm lg:mt-0 lg:mr-lg">
          {activeTab === 'matched' ||
            (activeTab === 'interested' &&
              helper.getCandidateCanBeInterviewed(candidate, job)) ? (
            <RequestInterviewButton
              candidateID={candidate.id}
              candidate={candidate}
              status={candidateStatus}
              companyID={data && data.company && data.company[0] && data.company[0].id}
              remainingCredit={data && data.company && data.company[0] && data.company[0].remainingCredit}
              subscriptionType={data && data.company && data.company[0].subscription_type}
            />
          ) : activeTab === 'interested' && (candidateStatus === 'interested' || helper.getCandidateOrRequestStatusIsApproved(candidate, job)) ? (
            <UnblockButton candidateID={candidate.id} status={candidateStatus} candidate={candidate} />
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

function JobCandidates ({ jobID, job }) {
  const [matchedCandidates, setMatchedCandidates] = useState();
  const [interestedCandidates, setInterestedCandidates] = useState();
  const [notInterestedCandidates, setNotInterestedCandidates] = useState();

  const tabs = ['matched', 'interested', 'not interested', 'interviewing'];
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [jobInterviews, setJobInterviews] = useState([]);
  const [candidatesShowing, setCandidatesShowing] = useState([]);

  const { data } = useSubscription(GET_MATCHED_CANDIDATES, { variables: { jobID } });

  useEffect(() => {
    if (data) {
      setMatchedCandidates(data.job_candidate.map(({ candidate, status }) => ({ ...candidate, status })));
    }
  }, [data]);

  const { data: interestedData } = useSubscription(GET_INTERESTED_CANDIDATES, { variables: { jobID } });
  useEffect(() => {
    if (interestedData) {
      setInterestedCandidates(interestedData.job_candidate.map(({ candidate, status, company_request, job }) => ({ ...candidate, status, company_request, job })));
    }
  }, [interestedData]);

  const { data: allInterviews } = useSubscription(GET_JOB_INTERVIEWS, { variables: { jobID } });

  useEffect(() => {
    if (allInterviews) {
      const interviews = new Set(allInterviews.job_interview.map(({ candidate }) => JSON.stringify(candidate)));
      setJobInterviews([...interviews].map((candidate) => JSON.parse(candidate)));
    }
  }, [allInterviews]);

  const { data: notInterestedData } = useSubscription(GET_NOT_INTERESTED_CANDIDATES, { variables: { jobID } });
  useEffect(() => {
    if (notInterestedData) {
      setNotInterestedCandidates(notInterestedData.job_candidate.map(({ candidate, status, company_request, job }) => ({ ...candidate, status, company_request, job })));
    }
  }, [notInterestedData]);

  const filterCandidates = () => {
    switch (activeTab) {
      case 'matched':
        setCandidatesShowing(matchedCandidates ? matchedCandidates : []);
        break;
      case 'interested':
        setCandidatesShowing(interestedCandidates ? interestedCandidates : []);
        break;
      case 'interviewing':
        setCandidatesShowing(jobInterviews);
        break;
      case 'not interested':
        setCandidatesShowing(notInterestedCandidates ? notInterestedCandidates : []);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    filterCandidates();
  }, [activeTab, matchedCandidates, interestedCandidates, interestedCandidates, notInterestedCandidates]);

  return (
    <div className="flex flex-col w-full h-full " style={{ maxWidth: 767 }}>
      <div className="flex items-center justify-between py-sm" style={{ height: 56 }}>
        <div className="text-darkblue font-medium" style={{ fontSize: 21 }}>
          Candidates
        </div>
        <div className="flex items-center font-semibold text-xs lg:text-sm" style={{ height: 40 }}>
          {candidatesShowing.length > 0 ? <MatchedCandidates candidates={candidatesShowing} activeTab={activeTab} job={job} /> : ''}
          {tabs.map((tab, index) => (
            <div key={index} onClick={() => setActiveTab(tab)} className={`${tab === activeTab ? 'text-green' : 'text-darkgray'} mr-sm capitalize lg:mr-lg cursor-pointer`}>
              {tab}
            </div>
          ))}
        </div>
      </div>
      <div
        className="bg-white relative rounded shadow mb-lg w-full h-full overflow-auto"
        style={{ minHeight: 300, maxHeight: 'calc(100vh - 210px)', maxWidth: 767, marginTop: 8, overflow: 'auto' }}
      >
        {candidatesShowing.length === 0 ? (
          matchedCandidates ? (
            <div className="absolute inset-0 flex items-center justify-center">No candidate is {activeTab} with this job</div>
          ) : null
        ) : (
          candidatesShowing.map((candidate, index) => (
            <CandidateEntry key={index} candidate={candidate} disableChat={firebase.auth().currentUser && firebase.auth().currentUser.uid !== candidate.recruiter_id} />
          ))
        )}
      </div>
    </div>
  );
}

function SubscriberJobCandidates ({ job, jobID, isSubscriber }) {
  const [currCompany, setCurrCompany] = useState();

  const { data } = useQuery(GET_COMPANY_ID, {
    variables: {
      admin_id: firebase.auth().currentUser && firebase.auth().currentUser.uid,
    },
  });
  useEffect(() => {
    if (data) {
      setCurrCompany(data.company[0]);
    }
  }, [data]);

  // const tabs = ["matched", "interested", "not-interested"];
  const tabs = ['interested'];
  // const [activeTab, setActiveTab] = useState(tabs[0]);
  const activeTab = tabs[0];

  const [candidatesShowing, setCandidatesShowing] = useState([]);

  const { data: matchedData } = useSubscription(GET_MATCHED_CANDIDATES, {
    variables: { jobID },
  });

  const { data: interestedData } = useSubscription(GET_INTERESTED_CANDIDATES, { variables: { jobID } });

  // const { data: notInterestedData } = useSubscription(GET_NOT_INTERESTED_CANDIDATES, { variables: { jobID } });

  const filterCandidates = () => {
    switch (
    activeTab
    // case "matched":
    //   if (matchedData) {
    //     setCandidatesShowing(
    //       matchedData.job_candidate.map(({ candidate, status, company_request }) => ({
    //         ...candidate,
    //         status: company_request.length !== 0 && company_request[0].status ? company_request[0].status : status
    //       }))
    //     );
    //   }
    //   break;
    // case "interested":
    //   if (interestedData) {
    //     setCandidatesShowing(
    //       interestedData.job_candidate.map(({ candidate, company_request }) => ({
    //         ...candidate,
    //         status: company_request.length !== 0 && company_request[0].status ? company_request[0].status : "interested"
    //       }))
    //     );
    //   }
    //   break;
    // case "not-interested":
    //   if (notInterestedData) {
    //     setCandidatesShowing(
    //       notInterestedData.job_candidate.map(({ candidate, company_request }) => ({
    //         ...candidate,
    //         status: company_request.length !== 0 && company_request[0].status ? company_request[0].status : "request"
    //       }))
    //     );
    //   }
    //   break;
    ) {
    }
  };

  useEffect(() => {
    filterCandidates();
  }, [activeTab, matchedData]);

  useEffect(() => {
    if (interestedData) {
      console.log("Interested Data", interestedData);
      setCandidatesShowing(
        interestedData.job_candidate.map(({ candidate, company_requests: companyRequest, status, job }) => {
          let computedStatus = '';

          for (const request of companyRequest) {
            if (request.status === 'approved') {
              computedStatus = 'approved';
            }
          }

          if (!computedStatus) {
            if (status === 'interested') {
              computedStatus = 'interested';
            }
          }

          if (!computedStatus) {
            computedStatus = _.get(
              companyRequest.filter(({ company_id: companyId }) => companyId === currCompany.id),
              '[0].status',
              'interested',
            );
          }

          return {
            ...candidate,
            job,
            company_request: companyRequest,
            status: computedStatus,
          };
        }),
      );
    }
  }, [interestedData]);

  return (
    <div className="flex flex-col w-full h-full " style={{ maxWidth: 767 }}>
      <div className="flex items-center justify-between py-sm" style={{ height: 56 }}>
        <div className="text-darkblue font-medium" style={{ fontSize: 21 }}>
          Interested Candidates
        </div>
        {/* <div
          className="flex items-center font-semibold text-xs lg:text-sm"
          style={{ height: 40 }}>
          {tabs.map(tab => (
            <div
              onClick={() => setActiveTab(tab)}
              className={`${tab === activeTab ? "text-green" : "text-darkgray"
                } mr-sm capitalize lg:mr-lg cursor-pointer`}>
              {tab}
            </div>
          ))}
        </div> */}
      </div>
      <div
        className="bg-white relative rounded shadow mb-lg w-full h-full overflow-auto"
        style={{ minHeight: 300, maxHeight: 'calc(100vh - 210px)', maxWidth: 767, marginTop: 8, overflow: 'auto' }}
      >
        {candidatesShowing.length === 0 ? (
          <div className="absolute inset-0 flex items-center justify-center">No candidate is {activeTab} with this job</div>
        ) : (
          candidatesShowing.map((candidate, index) => (
            <JobCandidateEntry
              key={index}
              candidate={candidate}
              disableChat={firebase.auth().currentUser && firebase.auth().currentUser.uid !== candidate.recruiter_id}
              isSubscriber={isSubscriber}
              jobID={jobID}
              job={job}
              activeTab={activeTab}
            />
          ))
        )}
      </div>
    </div>
  );
}

export default function JobDetails ({ location, navigate }) {
  const [, setModal] = useContext(ConfirmModalContext);
  const [job, setJob] = useState({});
  const [candidates, setCandidates] = useState();
  const [jobCandidates, setJobCandidates] = useState([]);

  const [userRole] = useContext(UserRoleContext);
  const isAdmin = userRole === 'admin';
  const isSubscriber = userRole === 'company';
  const isRecruiter = userRole === 'recruiter';

  const { data: jobData } = useSubscription(GET_JOB, {
    variables: { jobID: location.state && location.state.jobID },
  });

  const { data: candidateData } = useQuery(GET_ALL_CANDIDATES, {
    variables: {
      jobID: location.state && location.state.jobID,
      recruiterID: firebase.auth().currentUser && firebase.auth().currentUser.uid,
    },
  });

  const onClickTrashCan = () => {
    setModal({
      open: true,
      title: `Confirm Job Removal`,
      message: `Are you sure you want to remove the job ${job.title}?`,
      buttonText: 'Remove Job',
      action: removeJob,
    });
  };
  function removeJob () {
    removeCurrentJob({
      variables: { id: location.state.jobID },
    });
  }
  const [removeCurrentJob, { data: removeJobData }] = useMutation(DELETE_JOB);

  useEffect(() => {
    if (removeJobData) {
      setModal({
        loading: false,
        open: false,
      });
      navigate(isAdmin || isSubscriber ? '/dashboard/jobs' : '/jobs/open-jobs');
    }
  }, [removeJobData]);

  useEffect(() => {
    if (!location.state) {
      navigate(isAdmin ? '/dashboard/jobs' : '/jobs/open-jobs');
    }
  }, []);

  useEffect(() => {
    if (candidateData) {
      const { candidate, job_candidate: jobCandidate } = candidateData;
      setCandidates(candidate);
      setJobCandidates(jobCandidate.map(({ candidate_id: candidateId }) => candidateId));
    }
  }, [candidateData]);

  useEffect(() => {
    if (jobData) {
      const {
        job: [jobResponse],
      } = jobData;
      setJob({
        id: location.state.jobID,
        title: jobResponse.title,
        company: jobResponse.company ? jobResponse.company : jobResponse.subscriber,
        recruiterName: jobResponse.recruiter.name,
        salaryMax: jobResponse.salaryMax,
        salaryMin: jobResponse.salaryMin,
        location: jobResponse.location,
        requiredExperience: jobResponse.requiredExperience,
        industry: jobResponse.industry,
        compensation_list: jobResponse.compensation_list,
        description: jobResponse.description,
        hiddenFromCandidates: jobResponse.hiddenFromCandidates,
        attributes: {
          required: jobResponse.requiredAttributes,
          optional: jobResponse.optionalAttributes,
        },
        allowsRemote: jobResponse.allowsRemote,
        notes: jobResponse.notes ? (typeof jobResponse.notes === 'string' ? JSON.parse(jobResponse.notes) : jobResponse.notes) : [],
        subscriber_id: jobResponse.subscriber_id,
        adminApproved: jobResponse.adminApproved,
        created_at: jobResponse.created_at,
        post_date: jobResponse.created_at,
      });
    }
  }, [jobData]);

  const getElapsedTime = (postDate) => {
    const difference = moment().utc() - moment(postDate).utc();

    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;
    const msPerMonth = msPerDay * 30;
    const msPerYear = msPerDay * 365;

    let timeElapsed = '';
    if (difference < msPerMinute) {
      timeElapsed = Math.round(difference / 1000) + ' seconds ago';
    } else if (difference < msPerHour) {
      timeElapsed = Math.round(difference / msPerMinute) + ' minutes ago';
    } else if (difference < msPerDay) {
      timeElapsed = Math.round(difference / msPerHour) + ' hours ago';
    } else if (difference < msPerMonth) {
      timeElapsed = Math.round(difference / msPerDay) + ' days ago';
    } else if (difference < msPerYear) {
      timeElapsed = Math.round(difference / msPerMonth) + ' months ago';
    }
    return timeElapsed;
  };

  return (
    <div className="flex flex-col items-center justify-center w-full py-lg container" style={{ minHeight: '100%' }}>
      <div className="flex w-full">
        <div
          className="cursor-pointer text-darkgray flex items-center capitalize"
          style={{ fontSize: 12 }}
          /*onClick={() =>
            location.state && location.state.from
              ? navigate(location.state.from)
              : navigate(
                (isAdmin && !location.state.company_id) || isSubscriber
                  ? location.state.type == 'job-queue'
                    ? '/dashboard/job-queue'
                    : '/dashboard/jobs'
                  : (isAdmin || isRecruiter) && location.state.company_id
                    ? `/companies/${location.state.company_id}`
                    : location.state.type == 'job-queue'
                      ? '/dashboard/job-queue'
                      : '/jobs',
                {
                  state: { company_id: location.state.company_id },
                },
              )
          }*/
          onClick={() => window.history.back()}
        >
          {/* <BackButton /> */}
          <Arrow color="darkgray" style={{ height: 15, marginRight: 10 }} /> back
          {/* {location.state && location.state.company_id ? 'Company Detail' : location.state && location.state.type == 'job-queue' ? 'Job Queue' : 'Jobs'} */}
        </div>
      </div>
      <div className="flex flex-col lg:flex-row flex-1 w-full">
        <div className="job-details flex flex-col w-full lg:mr-xl" style={{ maxWidth: 530, minHeight: '100%' }}>
          <div className="flex items-center py-sm mb-sm">
            <div className="text-darkblue font-medium" style={{ fontSize: 21 }}>
              Job Detail
            </div>
            <div className="flex-1" />
            <div className="flex items-center mr-sm">
              <TrashButton onClick={onClickTrashCan} />
              {isAdmin || (isSubscriber && !job.adminApproved) || isRecruiter ? (
                <Link to={`/jobs/${job.id}/edit`} state={{ job }}>
                  <img alt="" src={EditIcon} style={{ width: 40, marginBottom: 0, cursor: 'pointer' }} />
                </Link>
              ) : (
                ''
              )}
            </div>
            {isRecruiter ? <AddCandidateButton jobID={location.state && location.state.jobID} candidates={candidates} jobCandidates={jobCandidates} /> : ''}
          </div>
          <div className="bg-white rounded shadow flex-1 mb-lg flex text-sm" style={{ padding: '30px 35px' }}>
            <div className="flex flex-wrap" style={{ maxWidth: '100%' }}>
              {job &&
                Object.entries(job).map(([label, value], index) => {
                  switch (label) {
                    case 'id':
                      return null;
                    case 'hiddenFromCandidates':
                      return null;
                    case 'salaryMin':
                      return null;
                    case 'attributes':
                      const attributes = value;
                      return Object.entries(attributes).map(([type, data], index) => (
                        <div key={index} className="w-full">
                          <div className="flex items-center">
                            <div className="darkblue mr-md capitalize" style={{ fontSize: 18 }}>
                              {type} Attributes
                            </div>
                            <div className="border-b flex-1" />
                          </div>
                          <div className="flex flex-wrap py-md mb-lg">
                            {(typeof data === 'string' ? JSON.parse(data) : data).map((attribute, index) => (
                              <div key={index} className="bg-lightgray text-darkgray mr-sm mt-sm flex items-center text-xs px-md" style={{ height: 28, borderRadius: 28 }}>
                                {attribute}
                              </div>
                            ))}
                          </div>
                        </div>
                      ));
                    case 'compensation_list':
                      return (
                        <div key={index} className="w-full">
                          <div className="flex items-center">
                            <div className="darkblue mr-md capitalize" style={{ fontSize: 18 }}>
                              Compensation
                            </div>
                            <div className="border-b flex-1" />
                          </div>
                          <div className="flex flex-wrap py-md mb-lg">
                            {value.map((attribute, index) => (
                              <div key={index} className="bg-lightgray text-darkgray mr-sm mt-sm flex items-center text-xs px-md" style={{ height: 28, borderRadius: 28 }}>
                                {attribute}
                              </div>
                            ))}
                          </div>
                        </div>
                      );

                    case 'description':
                      return (
                        <div key={index} className="w-full mb-lg">
                          <div className="flex items-center mb-md">
                            <div className="darkblue mr-md" style={{ fontSize: 18 }}>
                              Job Description
                            </div>
                            <div className="border-b flex-1" />
                          </div>
                          <div className="text-darkgray" style={{ fontSize: 14, lineHeight: '22px' }}>
                            {value ? parse(value) : 'N/A'}
                          </div>
                        </div>
                      );
                    case 'notes':
                      return <AdditionalNotes key={index} notes={value} jobID={location.state && location.state.job_id} />;
                    case 'allowsRemote':
                      return (
                        <div key={index} className="w-full mb-lg">
                          <div className="flex items-center mb-md">
                            <div className="darkblue mr-md" style={{ fontSize: 18 }}>
                              Allows Remote ?
                            </div>
                            <div className="border-b flex-1" />
                          </div>
                          <div className="text-darkgray" style={{ fontSize: 14, lineHeight: '22px' }}>
                            {value ? 'Yes' : 'No'}
                          </div>
                        </div>
                      );
                    default:
                      return (
                        <div key={index} className=" flex flex-col font-medium w-1/2">
                          <div className="capitalize text-darkgray pb-md">
                            {label === 'recruiterName'
                              ? 'Recruiter Name'
                              : label === 'requiredExperience'
                                ? 'Experience'
                                : label === 'salaryMax'
                                  ? 'Salary'
                                  : label === 'subscriber_id'
                                    ? ''
                                    : label === 'adminApproved'
                                      ? ''
                                      : label === 'allowsRemote'
                                        ? 'allowsRemote'
                                        : label === 'created_at'
                                          ? 'Post date'
                                          : label === 'post_date'
                                            ? 'Posted'
                                            : label}
                          </div>
                          <div className="text-darkblue pb-xl pr-lg">
                            {label === 'salary'
                              ? `$${value[0]}-${value[1]}`
                              : label === 'requiredExperience' && value
                                ? typeof value === 'string'
                                  ? `${JSON.parse(value)[0]}-${JSON.parse(value)[1]} years`
                                  : `${value[0]}-${value[1]} years`
                                : label === 'company'
                                  ? value && value.name
                                  : label === 'location'
                                    ? typeof value === 'string'
                                      ? JSON.parse(value).city
                                      : value.city
                                    : label === 'salaryMax'
                                      ? `$${formatSalary({ salary: job.salaryMin })} - $${formatSalary({ salary: job.salaryMax })}`
                                      : label === 'subscriber_id'
                                        ? ''
                                        : label === 'adminApproved'
                                          ? ''
                                          : label === 'created_at'
                                            ? moment(job.created_at).format('MM/DD/YYYY')
                                            : label === 'post_date'
                                              ? getElapsedTime(job.created_at)
                                              : value}
                          </div>
                        </div>
                      );
                  }
                })}
            </div>
          </div>
        </div>
        {isSubscriber ? (
          <SubscriberJobCandidates jobID={location.state && location.state.jobID} job={job} isSubscriber />
        ) : (
          <JobCandidates jobID={location.state && location.state.jobID} job={job} />
        )}
      </div>
    </div>
  );
}
