import React, { useContext } from 'react';
import LinkIcon from '../../images/link.png';
import { useSnackbar } from 'react-simple-snackbar';
import { ApolloContext } from '../../context/Apollo';
import { gql } from 'apollo-boost';
import { getLoxoCandidateDetails, getResumeFile } from '../../utils/loxo.helper';
import _ from 'lodash';

const GET_IMPORTER_ACCOUNT = gql`
  query getImporters($recruiterId: String, $accountType: String) {
    importers(where: { recruiter_id: { _eq: $recruiterId }, account_type: { _eq: $accountType } }) {
      recruiter_id
      account_type
      id
      is_connected
      authorization
    }
  }
`;

export function ViewResumeButton({ resumeUrl, candidateLoxoId, recruiterId }) {
  const [openSnackbar] = useSnackbar();
  const { apolloClient } = useContext(ApolloContext);

  const openResume = async () => {
    if (resumeUrl) {
      window.open(resumeUrl);
    } else if (candidateLoxoId) {
      openSnackbar(`Getting resume from loxo.`);
      // Get importer
      const response = await apolloClient.query({
        query: GET_IMPORTER_ACCOUNT,
        variables: { recruiterId, accountType: 'loxo' },
        fetchPolicy: 'network-only',
      });

      const importer = response.data.importers[0];

      if (!importer) {
        openSnackbar(`Loxo importer not configured.`);
        return;
      }

      // Get loxo candidate
      const loxoCandidate = await getLoxoCandidateDetails({ candidateId: candidateLoxoId, auth: importer.authorization });

      if (!loxoCandidate) {
        openSnackbar(`Candidate not found in loxo.`);
        return;
      }

      const latestResume = _.orderBy(loxoCandidate?.data?.resumes, 'id', 'desc')[0];

      if (!latestResume) {
        openSnackbar(`Resume not found from loxo.`);
        return;
      }

      // Get resume and open in new tab
      const blobUrl = await getResumeFile({ candidateLoxoId, resumeId: latestResume.id, auth: importer.authorization });

      window.open(blobUrl);

      openSnackbar(`Resume opened in new tab. ${latestResume.id}`);
    } else {
      openSnackbar(`No Resume found.`);
    }
  };

  return (
    <div className="flex">
      <div
        onClick={openResume}
        className="bg-lightgray flex shadow cursor-pointer rounded hover:underline items-center text-darkgray"
        style={{ paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10, marginTop: 5, fontSize: 12 }}
      >
        <img alt="" src={LinkIcon} style={{ height: 13, width: 13, marginBottom: 0, marginRight: 10 }} />
        View Resume
      </div>
    </div>
  );
}
