import { useMutation, useSubscription } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import axios from 'axios';
import firebase from 'firebase/app';
import { navigate } from 'gatsby';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { EXPRESS_SERVER_URL } from '../../config';
import BriefcaseIcon from '../../images/experience.png';
import EyeIcon from '../../images/eye-solid.svg';
import UserAvatar from '../../images/user-avatar.png';
import { generateInviteCode, getLongId } from '../../utils/getId';
import { generateInviteUrl } from '../../utils/google.helper';

const INSERT_CANDIDATE = gql`
  mutation insertCandidate($candidate: [candidate_insert_input!]!) {
    insert_candidate(objects: $candidate) {
      affected_rows
      returning {
        name
        email
        phone
        profilePictureURL
        linkedin
        recruiter_id
      }
    }
  }
`;

const GET_CANDIDATE = gql`
  subscription getCandidate($email: String, $recruiterId: String) {
    candidate(where: { email: { _eq: $email }, recruiter_id: { _eq: $recruiterId } }) {
      id
      profilePictureURL
      name
      email
      phone
      resumeFileName
      resumeURL
      linkedin
      referralName
      recruiter {
        id
        name
      }
      location
      salaryMax
      salaryMin
      facebook
      instagram
      twitter
      experience
      bio
      employment
      education
      certifications
      attributes
      preferredSkills
      notes
      locationPreferences
      interestedCity
      approved
      adminID
      is_login
      device_token
      invite_code
    }
  }
`;

const GET_CANDIDATE_BY_NAME = gql`
  subscription getCandidate($name: String, $recruiterId: String) {
    candidate(where: { name: { _eq: $name }, recruiter_id: { _eq: $recruiterId } }) {
      id
      profilePictureURL
      name
      email
      phone
      resumeFileName
      resumeURL
      linkedin
      referralName
      recruiter {
        id
        name
      }
      location
      salaryMax
      salaryMin
      facebook
      instagram
      twitter
      experience
      bio
      employment
      education
      certifications
      attributes
      preferredSkills
      notes
      locationPreferences
      interestedCity
      approved
      adminID
      is_login
      device_token
      invite_code
    }
  }
`;

const GET_CANDIDATE_BY_LOXO_ID = gql`
  subscription getCandidate($loxoId: String, $recruiterId: String) {
    candidate(where: { loxo_id: { _eq: $loxoId }, recruiter_id: { _eq: $recruiterId } }) {
      id
      profilePictureURL
      name
      email
      phone
      resumeFileName
      resumeURL
      linkedin
      referralName
      recruiter {
        id
        name
      }
      location
      salaryMax
      salaryMin
      facebook
      instagram
      twitter
      experience
      bio
      employment
      education
      certifications
      attributes
      preferredSkills
      notes
      locationPreferences
      interestedCity
      approved
      adminID
      is_login
      device_token
      invite_code
    }
  }
`;

export default function LoxoCandidateEntry({ candidate, isSelected, toggleCandidate, state, errorState, auth }) {
  const [setDetailModal] = state;
  const [isSynced, setIsSynced] = useState(false);
  const [recCandidate, setRecCandidate] = useState();
  const [employment, setEmployment] = useState();
  const [education, setEducation] = useState();
  const [location, setLocation] = useState();
  const [candEmp, setCandEmp] = useState([]);
  const [, setError] = errorState;

  const { data: candidateData } = useSubscription(
    candidate.emails.length > 0 ? GET_CANDIDATE : GET_CANDIDATE_BY_NAME,
    candidate.emails.length > 0
      ? { variables: { email: candidate.emails.length > 0 ? candidate.emails[0].value : '@@@', recruiterId: firebase.auth().currentUser && firebase.auth().currentUser.uid } }
      : { variables: { name: candidate.name ? candidate.name : null, recruiterId: firebase.auth().currentUser && firebase.auth().currentUser.uid } },
  );

  const { data: candidateByLoxoIdData } = useSubscription(GET_CANDIDATE_BY_LOXO_ID, {
    variables: {
      loxoId: candidate.id.toString(),
      recruiterId: firebase.auth().currentUser && firebase.auth().currentUser.uid,
    },
  });

  useEffect(() => {
    if (candidateByLoxoIdData && candidateByLoxoIdData.candidate && candidateByLoxoIdData.candidate.length > 0) {
      setIsSynced(true);
      setRecCandidate(candidateByLoxoIdData.candidate[0]);
    } else if (candidateData && candidateData.candidate && candidateData.candidate.length > 0) {
      setIsSynced(true);
      setRecCandidate(candidateData.candidate[0]);
    }
  }, [candidateData, candidateByLoxoIdData]);

  useEffect(() => {
    axios.get(`${EXPRESS_SERVER_URL}/get-loxo-candidate-details`, {
      params: {
        auth,
        candidateId: candidate.id,
      }
    }).then((response) => {
        if (response.status === 200) {
          setEmployment(response.data.job_profiles);
          setEducation(response.data.education_profiles);

          response.data.job_profiles &&
            response.data.job_profiles.forEach((emp) => {
              const startDate = moment()
                .set({ year: emp.year, month: (emp.month || 1) - 1, date: 1 })
                .startOf('day');

              let endDate = '';

              if (emp.end_month) {
                endDate = moment()
                  .set({ year: emp.end_year, month: (emp.end_month || 1) - 1, date: 1 })
                  .endOf('day');
              }

              const newElement = {
                id: getLongId(),
                companyLogo: '',
                companyLocation: '',
                title: emp.title,
                companyName: emp.company.name,
                description: emp.description,
                tenure: {
                  current: emp.end_month ? false : true,
                  startDate,
                  endDate,
                },
                skills: [],
              };

              setCandEmp((candEmp) => [...candEmp, newElement]);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const getCandidateDetail = (candidate) => {
    setDetailModal({
      open: true,
      data: {
        candidate,
        employment,
        education,
      },
    });
  };

  const [insertCandidate, { data: insertResponse, loading }] = useMutation(INSERT_CANDIDATE);

  useEffect(() => {
    if (insertResponse && insertResponse.insert_candidate && insertResponse.insert_candidate.returning) {
      setRecCandidate(insertResponse.insert_candidate.returning[0]);
      setIsSynced(true);
    }
  }, [insertResponse]);

  function getCoordinates(address) {
    fetch('https://maps.googleapis.com/maps/api/geocode/json?address=' + address + `&key=${process.env.GATSBY_GOOGLE_PLACES_KEY}`)
      .then((response) => response.json())
      .then((data) => {
        const latitude = _.get(data, 'results[0].geometry.location.lat', null);
        const longitude = _.get(data, 'results[0].geometry.location.lng', null);
        const formattedAddress = _.get(data, 'results[0].formatted_address', null);

        if (formattedAddress) {
          setLocation({ lat: latitude, lng: longitude, city: formattedAddress });
        } else {
          setLocation(null);
        }
      });
  }

  useEffect(() => {
    getCoordinates(candidate.city + ' ' + candidate.state);
  }, []);

  const experience =
    employment &&
    employment.reduce((totalExperience, { month, year, end_month: endMonth, end_year: endYear }) => {
      const start = moment(`${month}/01/${year}`);
      const end = endMonth ? moment(`${endMonth}/01/${endYear}`) : moment();
      return totalExperience + end.diff(start, 'months');
    }, 0);

  const uploadCandidate = async () => {
    if (candidate.emails.length > 0) {
      if (isSelected && !isSynced) {
        const user = await fetch(`${EXPRESS_SERVER_URL}/getUserFirebaseAccount`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            email: candidate.emails.length > 0 ? candidate.emails[0].value : '',
          }),
        }).then((response) => response.json());

        if (user.statusCode === 200) {
          setError('A candidate with the email address you provided already exists');
        } else {
          await fetch(`${EXPRESS_SERVER_URL}/createUserFirebaseAccount`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              email: candidate.emails.length > 0 ? candidate.emails[0].value : '',
              name: candidate.name,
            }),
          }).then((response) => response.json());

          const inviteCode = generateInviteCode();
          const inviteDeepLink = await generateInviteUrl({ inviteCode });

          const insertData = {
            id: Math.random()
              .toString(28)
              .substring(2),
            name: candidate.name ? candidate.name : '',
            email: candidate.emails.length > 0 ? candidate.emails[0].value : '',
            phone: candidate.phones && candidate.phones.length > 0 ? candidate.phones[0].value : '',
            profilePictureURL: candidate.profile_picture_thumb_url ? candidate.profile_picture_thumb_url : '',
            linkedin: candidate.linkedin_url ? candidate.linkedin_url : '',
            recruiter_id: firebase.auth().currentUser && firebase.auth().currentUser.uid,
            attributes: candidate.all_raw_tags && candidate.all_raw_tags.split(','),
            salaryMin: candidate.compensation && candidate.compensation !== 0 ? parseInt(candidate.compensation) : 0,
            created_at: candidate.created_at,
            approved: true,
            experience: experience ? Math.floor(experience / 12) : 0,
            location: location,
            employment: candEmp,
            loxo_id: candidate.id.toString(),
            invite_code: inviteCode,
            invite_deeplink: inviteDeepLink,
            invite_code_used_on: null,
            is_enriched: true,
          };

          // Default candidates will have remote preselected
          if (insertData?.locationPreferences) {
            insertData.locationPreferences.remote = true;
          }
          // If location preference is null or not defined, Set remote as true and other to false
          if (!insertData?.locationPreferences) {
            insertData.locationPreferences = {
              remote: true,
              currentLocation: false,
              willingToRelocate: false,
            }
          }

          insertCandidate({
            variables: { candidate: insertData },
          });
        }
      }
    } else {
      if (isSelected && !isSynced) {
        const inviteCode = generateInviteCode();
        const inviteDeepLink = await generateInviteUrl({ inviteCode });

        const insertData = {
          id: Math.random()
            .toString(28)
            .substring(2),
          name: candidate.name ? candidate.name : '',
          email: candidate.emails.length > 0 ? candidate.emails[0].value : '',
          phone: candidate.phones && candidate.phones.length > 0 ? candidate.phones[0].value : '',
          profilePictureURL: candidate.profile_picture_thumb_url ? candidate.profile_picture_thumb_url : '',
          linkedin: candidate.linkedin_url ? candidate.linkedin_url : '',
          recruiter_id: firebase.auth().currentUser && firebase.auth().currentUser.uid,
          attributes: candidate.all_raw_tags && candidate.all_raw_tags.split(','),
          salaryMin: candidate.compensation && candidate.compensation !== 0 ? parseInt(candidate.compensation) : 0,
          created_at: candidate.created_at,
          approved: true,
          experience: experience ? Math.floor(experience / 12) : 0,
          location: location,
          employment: candEmp,
          loxo_id: candidate.id.toString(),
          invite_code: inviteCode,
          invite_deeplink: inviteDeepLink,
          invite_code_used_on: null,
          is_enriched: true,
        };

        insertCandidate({
          variables: { candidate: insertData },
        });
      }
    }
  };

  return (
    <div className="flex items-center border-b py-3" style={{ opacity: 1, transition: 'opacity 0.3s ease-in-out' }}>
      <div style={{ width: 64, paddingRight: 15 }}>
        <div
          style={{
            margin: 0,
            height: 49,
            width: 49,
            borderRadius: 40,
            backgroundImage: `url(${candidate.profile_picture_thumb_url ? candidate.profile_picture_thumb_url : UserAvatar})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
          className="hover:underline lg:p-0 cursor-pointer"
        />
      </div>
      <div style={{ paddingLeft: 0, paddingRight: 0, width: 420 }}>
        <div className="flex flex-col">
          <div style={{ display: 'inline-flex' }}>
            <div
              onClick={() => (isSynced ? navigate(`/candidates/${recCandidate.id}`, { state: { candidate: recCandidate } }) : '')}
              className={`${isSynced ? 'hover:underline' : ''} lg:p-0 ${isSynced ? 'cursor-pointer' : ''} lg:text-sm`}
              style={{ fontWeight: 500 }}
            >
              {candidate.name}
            </div>
            <img
              title='Preview'
              alt=""
              src={EyeIcon}
              onClick={() => getCandidateDetail(candidate)}
              style={{ width: 17, height: 17, cursor: `pointer`, marginLeft: 10, marginTop: 2, marginBottom: 0 }}
            />
          </div>
          <div className="font-medium" style={{ fontSize: 14, maxWidth: 402 }}>
            <div className="text-darkblue">{candidate.current_title}</div>
            <div className="text-darkgray">{candidate.current_company}</div>
          </div>
        </div>
      </div>
      <div style={{ width: 350 }}>
        <div className="text-xs text-darkgray flex items-center mr-sm lg:mr-0 break-all" style={{ fontWeight: 500 }}>
          <img alt="" src={BriefcaseIcon} style={{ height: 20, margin: 0, marginLeft: 2, marginRight: 8 }} />
          {candidate.emails.length > 0 ? candidate.emails[0].value : ''}
        </div>
      </div>
      <div style={{ paddingRight: 0, width: 100 }}>
        <div className="text-xs text-darkgray flex items-center" style={{ fontWeight: 500, whiteSpace: 'nowrap' }}>
          {moment(new Date(candidate.created_at)).format('MM/DD/YYYY')}
        </div>
      </div>
      <div>
        <span
          className={`${isSynced ? 'bg-lightgreen' : ''} text-green border-green flex items-center justify-center px-md rounded border w-full text-xs font-medium`}
          style={{ height: 40, width: 120, cursor: 'default' }}
        >
          {loading ? <Loader type="TailSpin" className="flex justify-center" color="#000" height={20} width={20} /> : isSynced ? 'Synced' : 'Sync'}
        </span>
      </div>
      <div style={{ paddingLeft: 20, paddingRight: 0 }}>
        <button
          onClick={() => (isSynced ? navigate(`/candidates/${recCandidate.id}/edit`, { state: { candidate: recCandidate } }) : '')}
          className={`hover:bg-lightgreen text-green border-green flex items-center justify-center px-md rounded border w-full text-xs font-medium`}
          style={{ height: 40, width: 120, cursor: isSynced ? 'pointer' : 'not-allowed' }}
        >
          {'Update'}
        </button>
      </div>
      <div style={{ paddingLeft: 50, paddingRight: 0 }}>
        <div onClick={toggleCandidate} className={`${isSelected ? 'bg-green' : ''} border border-green cursor-pointer`} style={{ width: 14, height: 14 }} />
      </div>
    </div>
  );
}
