import axios from 'axios';
import { serialize } from 'object-to-formdata';
import { EXPRESS_SERVER_URL } from '../config';

export async function getLoxoCandidateDetails({ candidateId, auth }) {
  try {
    const { data } = await axios.get(`${EXPRESS_SERVER_URL}/get-loxo-candidate-details`, {
      params: {
        auth,
        candidateId: candidateId,
      }
    })
    /*
    const { data } = await axios.get(`https://app.loxo.co/api/cherrypicker/people/${candidateId}`, {
      headers: { Accept: 'application/json', Authorization: auth },
    });
    */
    return data;
  } catch (e) {
    return {};
  }
}

//Not used
export async function getLoxoCandidateEmployment({ candidateId, auth }) {
  try {
    const { data } = await axios.get(`https://app.loxo.co/api/cherrypicker/people/${candidateId}`, {
      headers: { Accept: 'application/json', Authorization: auth },
    });

    return data.job_profiles || [];
  } catch (e) {
    return [];
  }
}

export async function updateLoxoCandidateEmployment({ candidateId, auth, payload }) {
  try {
    const formData = serialize(payload, { indices: true });

    const config = {
      method: 'patch',
      url: `https://app.loxo.co/api/cherrypicker/people/${candidateId}`,
      headers: {
        Authorization: auth,
        Accept: 'application/json',
      },
      data: formData,
    };

    // const response = await axios(config);
    await axios.post(`${EXPRESS_SERVER_URL}/update-loxo-candidate-employment`, {
      config, 
    })
    // console.log(response.data);
  } catch (e) {
    console.log({ e });
  }
}

export async function getResumeFile({ candidateLoxoId, resumeId, auth }) {
  try {
    /*
    const res = await fetch(`https://app.loxo.co/api/cherrypicker/people/${candidateLoxoId}/resumes/${resumeId}/download`, {
      method: 'GET',
      headers: {
        Authorization: auth,
      },
    });
    */
    const response = await fetch(
      `${EXPRESS_SERVER_URL}/get-loxo-resume-file?candidateLoxoId=${candidateLoxoId}&auth=${auth}&resumeId=${resumeId}`,
      {
        method: "GET",
      }
    );
    if (!response.ok) {
      throw new Error("Failed to download resume");
    }
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    return url;
    // const a = document.createElement("a");
    // a.href = url;
    // a.download = "resume.pdf"; // Set default file name
    // document.body.appendChild(a);
    // a.click();
    // document.body.removeChild(a);
    // window.URL.revokeObjectURL(url);
  } catch (e) {
    console.log(e);
    return '';
  }
}
